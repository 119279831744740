import React, { useEffect, useState } from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Avatar, Modal, ReviewRating, UserDisplayName } from '../../components';
import { propTypes } from '../../util/types';

import css from './Reviews.module.css';
import { isArrayLength } from '../../util/genericHelpers';

const Review = props => {
  const { review, intl, onManageDisableScrolling } = props;

  const date = review.attributes.createdAt;
  const images = review?.images;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });

  const [tosModalOpen, setTosModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tosModalOpen]);
  return (
    <>
      <div className={css.review}>
        <div className={css.reviewerSec}>
          <Avatar className={css.avatar} user={review.author} />
          <div className={css.reviewerHead}>
            <UserDisplayName className={css.userName} user={review.author} intl={intl} />
            {/* <p>Bucharest, Romania</p> */}
          </div>
        </div>
        <div className={css.reviewContentSec}>
          <div className={css.reviewInfo}>
            <span className={css.desktopReviewRatingWrapper}>
              <ReviewRating
                rating={review.attributes.rating}
                className={css.desktopReviewRating}
                reviewStarClassName={css.reviewRatingStar}
              />
            </span>
            {'·'}
            <span>{dateString}</span>
          </div>
          <p className={css.reviewContent}>{review.attributes.content}</p>
          {isArrayLength(images) ? (
            <span className={css.showMoreLink} onClick={() => setTosModalOpen(true)}>
              Show more
            </span>
          ) : null}
        </div>
      </div>
      <Modal
        id="AuthenticationPage.tos"
        isOpen={tosModalOpen}
        onClose={() => setTosModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.review}>
          <div className={css.reviewerSec}>
            <Avatar className={css.avatar} user={review.author} />
            <div className={css.reviewerHead}>
              <UserDisplayName className={css.userName} user={review.author} intl={intl} />
              {/* <p>Bucharest, Romania</p> */}
            </div>
          </div>
          <div className={css.reviewContentSec}>
            <div className={css.reviewInfo}>
              <span className={css.desktopReviewRatingWrapper}>
                <ReviewRating
                  rating={review.attributes.rating}
                  className={css.desktopReviewRating}
                  reviewStarClassName={css.reviewRatingStar}
                />
              </span>
              {'·'}
              <span>{dateString}</span>
            </div>
            <p className={classNames(css.reviewContent, css.modalReviewContent)}>
              {review.attributes.content}
            </p>
            {isArrayLength(images) ? (
              <div className={css.imagesReviews}>
                {images.map(i => (
                  <div className={css.imgThumb}>
                    <img src={i} alt="Reviews" />
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
    </>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const { className, rootClassName, reviews, intl, onManageDisableScrolling } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <ul className={classes}>
      {reviews.map(r => {
        return (
          <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
            <Review onManageDisableScrolling={onManageDisableScrolling} review={r} intl={intl} />
          </li>
        );
      })}
    </ul>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};
const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
